module.exports = [{
      plugin: require('/builds/oxylabs/projects/family-friendly/family-friendly-website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:400,500,700"]}},
    },{
      plugin: require('/builds/oxylabs/projects/family-friendly/family-friendly-website/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K7N9NQ5","includeInDevelopment":false},
    },{
      plugin: require('/builds/oxylabs/projects/family-friendly/family-friendly-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140687604-1"},
    },{
      plugin: require('/builds/oxylabs/projects/family-friendly/family-friendly-website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/builds/oxylabs/projects/family-friendly/family-friendly-website/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"manualInit":true,"modulePath":"/builds/oxylabs/projects/family-friendly/family-friendly-website/src/cms/cms.js","htmlTitle":"Admin @ FFNDS"},
    },{
      plugin: require('/builds/oxylabs/projects/family-friendly/family-friendly-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
